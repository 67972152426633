import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gt-scores-video-tutorial" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          "is-open": _ctx.isModalOpen,
          "header-text": _ctx.$t('HOME.how_to_test'),
          height: 400,
          onOnModalOpen: _ctx.setIsModalOpen
        }, {
          default: _withCtx(() => [
            _createElementVNode("video", {
              class: "gt-scores-video-tutorial__video",
              src: './resource/Ondorio_instrukcja_ok.mp4',
              onTimeupdate: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendEvent && _ctx.sendEvent(...args))),
              onPlay: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.videoStarted && _ctx.videoStarted(...args))),
              controls: "",
              controlsList: "nodownload"
            }, null, 40, _hoisted_2)
          ]),
          _: 1
        }, 8, ["is-open", "header-text", "onOnModalOpen"]))
      : _createCommentVNode("", true)
  ]))
}