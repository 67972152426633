import { OmrAnswerCard, OmrAnswerCardGrouped } from '@/services/api/models/OmrAnswerCard';

// Home uses these mocks for slides
export const CardList: OmrAnswerCard[] = [
    {
        file: {
            process: '',
        },
        hash: '51144363458',
        score: {
            scoreMax: 14,
            scoreQuestion: [],
            scoreUser: 3,
            scoreUserAnswer: [],
            studentNumber: 2,
            answer: [{typeId: 4, type: 'single-choice', score: 1, answers: [[1], [0]]}]
        },
        studentNumber: 11,
        test: {
            numberExercise: 2,
            categoryKey: 'reforma-2017',
            numberRowsQuestion: 3,
            userId: 13131,
            key: 'sadad',
            hash: '21fsf4333434f3f',
            teacherArea: 5,
            testId: 23,
            title: 'Test title 2',
            variantId: 32
        },
        isChecked: false
    },
    {
        file: {
            process: '',
        },
        hash: '51144363457',
        score: {
            studentNumber: 2,
            scoreMax: 15,
            scoreQuestion: [],
            scoreUser: 6,
            scoreUserAnswer: [],
            answer: [{typeId: 4, type: 'single-choice', score: 1, answers: [[1], [0]]}]
        },
        studentNumber: 12,
        test: {
            numberExercise: 2,
            categoryKey: 'reforma-2017',
            numberRowsQuestion: 3,
            userId: 13131,
            key: 'sadad',
            hash: '21fsf4333434f3f',
            teacherArea: 5,
            testId: 23,
            title: 'Test title 2',
            variantId: 32
        },
        isChecked: false
    },
]

export const CardListGrouped: OmrAnswerCardGrouped[] = [
    {
        id: 2313,
        hash: 'asdfasdf',
        answerCardList: [
            {
                file: {
                    process: '',
                },
                hash: '51144363456',
                score: {
                    studentNumber:2,
                    scoreMax: 14,
                    scoreQuestion: [],
                    scoreUser: 3,
                    scoreUserAnswer: [],
                    answer: [{typeId: 4, type: 'single-choice', score: 1, answers: [[1], [0]]}]
                },
                studentNumber: 12,
                test: {
                    numberExercise: 2,
                    categoryKey: 'reforma-2017',
                    numberRowsQuestion: 3,
                    userId: 13131,
                    key: 'sadad',
                    hash: '21fsf4333434f3f',
                    teacherArea: 2,
                    testId: 2313,
                    title: 'Test title',
                    variantId: 22
                },
                isChecked: false
            },
        ]
    },
    {
        id: 23,
        hash: 'asdfasdf',
        answerCardList: [
            {
                file: {
                    process: '',
                },
                hash: '51144363455',
                score: {
                    studentNumber: 2,
                    scoreMax: 15,
                    scoreQuestion: [],
                    scoreUser: 6,
                    scoreUserAnswer: [],
                    answer: [{typeId: 4, type: 'single-choice', score: 1, answers: [[1], [0]]}]
                },
                studentNumber: 1,
                test: {
                    numberExercise: 2,
                    categoryKey: 'reforma-2017',
                    numberRowsQuestion: 3,
                    userId: 13131,
                    key: 'sadad',
                    hash: '21fsf4333434f3f',
                    teacherArea: 5,
                    testId: 23,
                    title: 'Test title 2',
                    variantId: 32
                },
                isChecked: false
            },
            {
                file: {
                    process: '',
                },
                hash: '51144363453',
                score: {
                    studentNumber: 2,
                    scoreMax: 15,
                    scoreQuestion: [],
                    scoreUser: 6,
                    scoreUserAnswer: [],
                    answer: [{typeId: 4, type: 'single-choice', score: 1, answers: [[1], [0]]}]
                },
                studentNumber: 11,
                test: {
                    numberExercise: 2,
                    categoryKey: 'reforma-2017',
                    numberRowsQuestion: 3,
                    userId: 13131,
                    key: 'sadad',
                    hash: '21fsf4333434f3f',
                    teacherArea: 5,
                    testId: 23,
                    title: 'Test title 2',
                    variantId: 32
                },
                isChecked: false
            },
        ]
    }
]
