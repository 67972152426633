
import { defineComponent } from 'vue';
import Modal from '@/components/shared/Modal.vue';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from "@/services/common/DataLayerModel";


export default defineComponent ({
  components: { Modal },
  props: {
    isModalOpen: Boolean
  },
  data() {
    return {
      eventSent25: false,
      eventSent50: false,
      eventSent75: false,
      eventSent100: false
    }
  },
  methods: {
    setIsModalOpen (isOpen: boolean): void {
      this.$emit('setIsVideoTutorialOpen', isOpen);
      if (!isOpen) {
        this.eventSent25 = false;
        this.eventSent50 = false;
        this.eventSent75 = false;
        this.eventSent100 = false;
      }
    },
    sendEvent (event: any) {
      const currentTime = Number(event.currentTarget.currentTime.toFixed());
      const videoTime = Number(event.currentTarget.duration.toFixed());

      switch (currentTime) {
        case Number((videoTime / 4).toFixed()):
          if (!this.eventSent25) {
            dataLayerPush(DataLayerEvent.VIDEO_WATCHED, {videoProgress: '25%'});
            this.eventSent25 = true;
          }
          break;
        case videoTime / 2:
          if (!this.eventSent50) {
            dataLayerPush(DataLayerEvent.VIDEO_WATCHED, {videoProgress: '50%'});
            this.eventSent50 = true;
          }
          break;
        case Number((videoTime - (videoTime / 4)).toFixed()):
          if (!this.eventSent75) {
            dataLayerPush(DataLayerEvent.VIDEO_WATCHED, {videoProgress: '75%'});
            this.eventSent75 = true;
          }
          break;
        case videoTime:
          if (!this.eventSent100) {
            dataLayerPush(DataLayerEvent.VIDEO_WATCHED, {videoProgress: '100%'});
            this.eventSent100 = true;
          }
          break;
      }
    },
    videoStarted () {
      dataLayerPush(DataLayerEvent.VIDEO_WATCHED, {videoProgress: 'video started'});
    }
  }
});
