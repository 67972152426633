
import {
  IonPage,
  IonContent,
} from '@ionic/vue';
import { defineComponent, reactive, toRefs } from 'vue';
import Card from "@/components/shared/Card.vue";
import Header from "@/components/shared/Header.vue";
import AnswerCardList from '@/views/AnswerCard/AnswerCardList/AnswerCardList.vue';
import { CardList } from '@/mocks/card-list';
import AnswerCardListHeader from '@/components/shared/AnswerCardListHeader.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {Mousewheel, Pagination} from 'swiper';
import 'swiper/swiper.scss';
import {ROUTE_HASH, RouteName} from '@/router/routeNames';
import { useRouter } from 'vue-router';
import { isIOS } from '@/utils/DeviceUtils';
import VideoTutorial from '@/views/Home/VideoTutorial/VideoTutorial.vue';
SwiperCore.use([Pagination, Mousewheel]);


export default defineComponent ({
  components: { VideoTutorial, Swiper, SwiperSlide, IonPage, IonContent, Card, Header, AnswerCardList, AnswerCardListHeader },
  setup () {
    const router = useRouter();
    const state = reactive({
      modules: [Pagination, Mousewheel],
      pagination: {
        clickable: true,
      },
      platform: 'web',
      isEmptyContainerVisible: isIOS(),
      mousewheel: true,
      isVideoTutorialOpen: false,
    });

    const openVideo = () => {
      router.push({ name: RouteName.HOME, hash: ROUTE_HASH.VIDEO_TUTORIAL_MODAL});
      state.isVideoTutorialOpen = true
    }

    const setIsVideoTutorialOpen = (isOpen: boolean) => {
      if (!isOpen) {
        router.replace({ name: RouteName.HOME })
      }
      state.isVideoTutorialOpen = isOpen;
    }

    const goToAnswerCard = () => {
      router.push(RouteName.ANSWER_CARD)
    }

    return {
      ...toRefs(state),
      cardList: [CardList[0]],
      goToAnswerCard,
      openVideo,
      setIsVideoTutorialOpen
    }
  },
  watch: {
    $route(to, from) {
      switch (from.hash) {
        case ROUTE_HASH.VIDEO_TUTORIAL_MODAL:
          this.isVideoTutorialOpen = false;
          break;
      }
    }
  }
});

